@media screen and (max-width: 1442px){
    .wrapper {
        width: 75%;
    }
    .modal{
        width: 800px !important;
    }
}
@media screen and (max-width: 1368px){
    .wrapper {
        width: 70%;
    }
}
@media screen and (max-width: 1024px){
    .wrapper {
        width: 100%;
    }
    .modal{
        width:92% !important;
    }
    .medication-comment {
        .tooltip-inner {
          max-width: 250px !important;
        }
    }
}
@media screen and (max-width: 767px){
    .wrapper {
        width: 100%;
    }
    .verifymaintitlebox {
        padding:15px 12px !important;
        h2 {
            line-height: 32px;
        }
    }
    .documentinfobox .row .col.s4{
        padding: 0px 4px !important;
    }
    .app-form-render-html{
        .patientformheader {
            padding: 30px 0px !important;
        }
        .subpatientsection {
            padding-bottom: 0px !important;
        }
    }
    // .verifymainblock .verifymaininnerblock{
    //     height: calc(100vh - 220px) !important
    // }
}
@media only screen and (max-width: 567px){
    .row .col.s4, .row .col.s6{
        float: left !important;
        width:100% !important;
        margin-bottom:10px;
        flex: inherit !important;
    }
    .patientformheadtitle {
        text-align: center;
        padding-right: 0;
        order: 2;
    }
    .patientformclientlogo {
        flex: inherit !important;
        display: block !important;
        margin:0px auto 10px !important;
    }
    .subpatientformmain-section .patientformheader {
        width: auto !important;
        flex-direction: column;
        text-align: center;
        justify-content: center;
    }
    .patientformheadtitle h1:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100px;
        height: 1px;
        background-color: #343434;
        right: 0;
        margin: 0 auto;
    }
}
@media screen and (max-width: 420px){
    .patientsignfilebox {
        height: 140px !important;
    }
    .medication-comment {
        .tooltip-inner {
          max-width: 200px !important;
        }
    }
}
